.ToothSelector__axis_line {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ToothSelector__chart_label {
  font-size: 24px;
}

.ToothSelector__number {
  font-size: 22px;
  font-weight: 700;
  fill: #000;
}

.ToothSelector--editable .ToothSelector__tooth-container {
  cursor: pointer;
}

.ToothSelector__number_circle {
  fill: #fff;
  transition: fill 200ms;
}

.ToothSelector__tooth {
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  fill: #fff;
  transition: fill 200ms;
}
.ToothSelector--editable
  .ToothSelector__tooth-container:hover
  .ToothSelector__number_circle {
  fill: rgb(193, 213, 240);
}

.ToothSelector--editable
  .ToothSelector__tooth-container:hover
  .ToothSelector__tooth {
  fill: rgb(168, 189, 216);
}

.ToothSelector__tooth-container--on .ToothSelector__number_circle {
  fill: rgb(173, 200, 255);
}

.ToothSelector__tooth-container--on .ToothSelector__tooth {
  fill: rgb(173, 200, 255);
}
